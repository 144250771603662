import {
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { AppFacade } from "@app/app.facade";
import { pageEvent, pageViewEvent } from "@app/helpers/utag.helpers";
import { Languages } from "@app/store/actions/app.action";
import { IIdentifiTypesState } from "@app/store/state/app.state";
import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { IDocumentType } from "../shared/models/form-validation-messages";
import { CommonsService } from "../shared/services/commons.service";
import { IRegisterValidationMessages } from "../singup/modules/register/models/register";
import { DocumentsService } from "../singup/services/documents.service";
import { InputComponent } from "../theme/components/input/input.component";
import { DataPolicyService } from "./services/data-policy.service";

@Component({
  selector: "fp-data-policy",
  templateUrl: "./data-policy.component.html",
  styleUrls: ["./data-policy.component.scss"],
})
export class DataPolicyComponent implements OnInit, OnDestroy {
  @HostBinding("class") fpRegister = "fp-data-policy";
  destroy$: Subject<boolean> = new Subject<boolean>();
  formRegister: UntypedFormGroup;
  registerValidationMessages: IRegisterValidationMessages;
  viewMessageError: boolean = false;
  readOnlyTC: boolean = true;
  readOnlyHabeas: boolean = true;
  sending: boolean = false;

  @ViewChild("idnumber") inputIdNumber: InputComponent;
  @ViewChild("inputRegister") inputRegister: InputComponent;
  @ViewChild("inputEmail") inputEmail: InputComponent;
  typeDocuments: IDocumentType[] = [
    {
      value: "1",
      description: "C.C.",
    },
  ];
  messageError: string;
  timeEvent: number;
  urlDataPolicy: string = "";
  versionDataPolicy: string = "";

  constructor(
    public translate: TranslateService,
    private appFacade: AppFacade,
    private router: Router,
    private dataPolicyService: DataPolicyService,
    private commonsService: CommonsService,
    private documentService: DocumentsService,
  ) {}

  /**
   * Init to register form
   */
  initForm(): void {
    this.sending = false;
    this.timeEvent = new Date().getTime();
    this.formRegister = new UntypedFormGroup({
      identificationType: new UntypedFormControl("", [Validators.required]),
      identificationNumber: new UntypedFormControl("", [
        Validators.required,
        Validators.pattern("^[0-9]{5,10}$"),
      ]),
      name: new UntypedFormControl("", [
        Validators.required,
        Validators.pattern("^[A-Za-zÁÉÍÓÚáéíóúñÑ ]{2,50}$"),
      ]),
      lastname: new UntypedFormControl("", [
        Validators.required,
        Validators.pattern("^[A-Za-zÁÉÍÓÚáéíóúñÑ ]{2,50}$"),
      ]),
      email: new UntypedFormControl("", [
        Validators.required,
        Validators.pattern(
          "^([a-zA-Z0-9-+_\\-\\.]+)@([a-zA-Z0-9_\\-\\.]+)\\.([a-zA-Z]{2,5})$",
        ),
      ]),
      phone: new UntypedFormControl("", [
        Validators.pattern("^[3]{1}[0-9]{9}$"),
      ]),
      personalData: new UntypedFormControl(false, [
        Validators.required,
        Validators.requiredTrue,
      ]),
    });
    this.registerValidationMessages = {
      identificationType: [
        { type: "required", message: "register_error_required" },
      ],
      identificationNumber: [
        {
          type: "required",
          message: "form_errors.identificationNumber.required",
        },
        {
          type: "pattern",
          message: "form_errors.identificationNumber.pattern",
        },
        {
          type: "prospect_valid",
          message: "form_errors.identificationNumber.exist",
        },
      ],
      name: [
        { type: "required", message: "form_errors.name.required" },
        { type: "pattern", message: "form_errors.name.pattern" },
      ],
      lastname: [
        { type: "required", message: "form_errors.lastname.required" },
        { type: "pattern", message: "form_errors.lastname.pattern" },
      ],
      email: [
        { type: "required", message: "form_errors.email.required" },
        { type: "pattern", message: "form_errors.email.pattern" },
        { type: "exist", message: "form_errors.email.exist" },
        { type: "exit_phone_email", message: "" },
      ],
      phone: [
        { type: "pattern", message: "form_errors.phone.pattern" },
        { type: "exit_phone_email", message: "form_errors.exit_phone_email" },
      ],
    };
  }

  /**
   * Select the first document obtained in the service
   *
   * @param documentType Colombian Document Type
   */
  chooseDocumentType(documentType: string): void {
    this.formRegister.controls.identificationType.setValue(documentType);
    this.setErrorInput(false);
  }

  /**
   * Set errot to Input in form
   *
   * @param setErrot boolean error
   */
  setErrorInput(setErrot: boolean): void {
    if (setErrot) {
      this.formRegister
        .get("identificationNumber")
        .setErrors({ prospect_valid: true });
      this.formRegister.get("identificationNumber").markAsTouched();
    } else {
      this.formRegister.get("identificationNumber").updateValueAndValidity();
    }
    this.inputIdNumber.updateInput();
  }

  /**
   * Init state to component
   */
  async ngOnInit(): Promise<any> {
    pageViewEvent("/data-policy");
    this.selectLanguage$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (value) {
        this.translate.use(value);
      }
    });
    this.initForm();
    this.loadHabeasData();
    await this.getInitialState();
  }

  /**
   * Unsubscribe observables
   */
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  /**
   * get data of language selector
   */
  get selectLanguage$(): Observable<Languages> {
    return this.appFacade.selectLanguage$;
  }

  /**
   * Validate an existing user
   */
  async registerUser(): Promise<void> {
    this.sending = true;
    pageEvent(
      "click-btn-accept-data-policy",
      "/data-policy",
      "accept personal data policy",
    );
    const data = {
      eventCode: "70201",
      details: {
        name: this.formRegister.value["name"],
        lastNames: this.formRegister.value["lastname"],
        email: this.formRegister.value["email"],
        phone: this.formRegister.value["phone"],
        policyDataPersonal: this.formRegister.value["personalData"],
        versionDataPolicy: this.versionDataPolicy,
        clientIdType: this.formRegister.value["identificationType"],
        clientId: this.formRegister.value["identificationNumber"],
      },
      result: true,
      startExecution: this.timeEvent,
      businessMessages: {
        code: "OK",
        message: "SUCCESS",
      },
      channel: "pn",
      eventName: "ACT POLITICA DATOS",
      mnemonic: "ACT POL DT",
    };
    await this.commonsService.getTokenNgCaptcha();
    this.dataPolicyService.registerUser(data).subscribe((response) => {
      this.appFacade.setLoaderShow({
        type: "GENERAL",
      });
      this.router.navigate([`/data-policy/successful`]);
      this.appFacade.setLoaderHide();
      this.sending = false;
    });
  }

  inputEvent($event, inputModal: any): void {
    if (
      $event.inputType === "insertFromPaste" ||
      $event.inputType === "insertText"
    ) {
      if (
        inputModal.formControl.value.length > 0 &&
        inputModal.formControl.value[0] !== "3"
      ) {
        this.viewMessageError = true;
        this.setErrorInputGeneric();
      } else {
        this.viewMessageError = false;
      }
    }
    return;
  }

  /**
   * Get Initial state
   */
  async getInitialState(): Promise<any> {
    const promiseDocumentTypes = await this.selectIdentificationType$
      .pipe(take(1))
      .toPromise();
    if (promiseDocumentTypes.documenTypes === null) {
      this.appFacade.getIdentificationTypes();
      this.selectIdentificationType2$
        .pipe(take(2))
        .subscribe((action: IIdentifiTypesState) => {
          if (action && action.documenTypes !== null) {
            this.loadUserState(action);
          }
        });
    }
    if (promiseDocumentTypes.documenTypes !== null) {
      this.loadUserState(promiseDocumentTypes);
    }
  }

  /**
   *
   * @param action Action
   */
  loadUserState(action: IIdentifiTypesState): void {
    this.typeDocuments = action.documenTypes.data;
    this.chooseDocumentType(this.typeDocuments[0].value);
  }

  openUrl() {
    window.open(this.urlDataPolicy, "_blank");
  }

  /**
   * Set errot to Input in form
   *
   * @param control
   * @param error
   * @param input
   */
  setErrorInputGeneric(
    control: string = "phone",
    error: object = { pattern: true },
    input: string = "inputRegister",
  ): void {
    this.formRegister.get(control).setErrors(error);
    this.formRegister.get(control).markAsTouched();
    switch (input) {
      case "inputRegister":
      case "inputEmail":
        this.inputEmail.updateInput();
        break;
    }
  }

  loadHabeasData(): void {
    this.documentService.getHabeasData().subscribe((value) => {
      this.urlDataPolicy = value.data[0].documents[1].url;
      this.versionDataPolicy = value.data[0].documents[1].version;
    });
  }

  get selectIdentificationType$(): Observable<IIdentifiTypesState> {
    return this.appFacade.selectIdentificationType$;
  }
  get selectIdentificationType2$(): Observable<IIdentifiTypesState> {
    return this.appFacade.selectIdentificationType2$;
  }
}

<ng-container *ngIf="typeTemplate === 'firstTemplate'">
    <div class="container-vehicles">
        <div class="container-item-vehicles" *ngFor="let vehicle of vehicles">

            <fp-item-list-vehicle [vehicle]="vehicle" (itemClickActive)="onChangeStatus($event)"
                (vehicleInformation)="goTobuyTag($event)" [resetData]="error"
                typeTemplate="firstTemplate"
                (openModalDelete)="deleteVehicleShowModal($event)"
                (openModalManagement)="openModalManagement($event,vehicle)">
            </fp-item-list-vehicle>

        </div>
    </div>
</ng-container>


<ng-container *ngIf="typeTemplate !== 'firstTemplate'">
    <fp-item-list-vehicle *ngFor="let vehicle of vehicles; let indexEven = even" [vehicle]="vehicle"
        (itemClickActive)="onChangeStatus($event)" (vehicleInformation)="goTobuyTag($event)" [resetData]="error"
        [indexEven]="indexEven" (openModalManagement)="openModalManagement($event,vehicle)"
        (openModalDelete)="deleteVehicleShowModal($event)">
    </fp-item-list-vehicle>
</ng-container>


<fp-vehicles-management [vehicleInfo]="itemVehicleSelected" [loading]="loadingChange"
    (emitChangeStatus)="onChangeStatus($event)" (emitCloseModal)="onCloseModal('fp-vehicles-management')"
    (openModalDelete)="deleteVehicleShowModal($event)">
</fp-vehicles-management>

<!-- Modal para eliminar vehículo -->
<fp-delete-vehicle [vehicleInfo]="vehicleInfoToDelete" [category]="category"
    (itemClickActionDelete)="onDeleteVehicle($event)">
</fp-delete-vehicle>

<!-- Modal para activación/inactivación -->
<fp-activation-progress (itemClickActive)="navigatetoRecharge()" [titleModal]="titleModal"
    [labelBtnAccept]="labelBtnAccept" [hideClose]="hideClose">
</fp-activation-progress>

<ng-template #tempError>
    <fp-snack-message (snackMessageEvent)="closeAlertActive()" [title]="'Error' "
        [description]="messageError | translate " type="error">
    </fp-snack-message>
</ng-template>
/**
 * Import libraries
 */
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AppFacade } from "@app/app.facade";
import { IPage, IPageListParams } from "@app/models/pages";
import { singUpPagesConst } from "@core/constants/pages.constant";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { IHabeasData } from "../models/habeas-data";
import { ITermsandConditions } from "../models/itermsand-conditions";
import { SingUpFacade } from "../store/facade/singup.facade";
/**
 * Declarate injectable to service
 */
@Injectable()
/**
 * Class to Singup Navigation Service
 */
export class SingupNavigationService {
  /**
   * Constructor
   *
   * @param router Router to navigate
   * @param singupFacade Facade to load resources
   * @param appFacade
   */
  constructor(
    private router: Router,
    private singupFacade: SingUpFacade,
    private appFacade: AppFacade,
  ) {}
  /**
   * Set data to configure next events
   *
   * @param position number position to singup constant
   */
  async next(position: number): Promise<any> {
    const page: IPage = singUpPagesConst[position];
    let savedPages: IPage[];
    savedPages = singUpPagesConst.slice(0, position + 1);
    if (position >= 3) {
      savedPages = [page];
    }
    this.singupFacade.setSingupPages(savedPages);
    if (page.list) {
      await this.loadFacade(page);
    } else {
      this.router.navigate([`/signup/${page.active}`]);
    }
  }
  /**
   * clearStore()
   */
  clearStore(): void {
    this.singupFacade.setTemporalPassword({ temporalPassword: null });
    this.singupFacade.setSingupPages(singUpPagesConst.slice(0, 1));
    this.singupFacade.resetUserData();
    this.singupFacade.setCheckState(false);
    this.singupFacade.setCheckHabeasDataState(false);
    this.singupFacade.setCheckTermsandConditionsState(false);
    this.singupFacade.resetOtpVinculationData();
    this.clearOtpTimer();
  }
  clearOtpTimer(): void {
    this.singupFacade.setOtpCodeTimer({ time: null });
  }
  /**
   * Navigate to back
   */
  previous(): void {}
  /**
   * Navigate to Error
   */

  navigatetoError(): void {
    this.clearStore();
    this.router.navigate(["/signup/vinculation-error"]);
  }

  navigatetoRestriction(): void {
    this.clearStore();
    this.router.navigate(["/signup/vinculation-restriction"]);
  }

  /**
   * goTo Login path
   */
  navigatetoLogin(): void {
    this.clearStore();
    this.router.navigate(["/signin/login"]);
  }
  /**
   * Method to start call to facade
   *
   * @param list list to get http resources
   * @param page
   */
  async loadFacade(page: IPage): Promise<void> {
    let lists: IPageListParams[] = JSON.parse(JSON.stringify(page.list));
    this.singupFacade.setOtpCodeTimer({ time: null });
    lists.forEach((list) => (list.state = false));

    for (const [index, list] of page.list.entries()) {
      switch (list.name) {
        case "terms-and-conditions":
          await this.processTermsAndConditions(lists, index, page.active);
          break;
        case "habeas-data":
          await this.processHabeasData(lists, index, page.active);
          break;
        default:
          break;
      }
    }
  }

  async processTermsAndConditions(
    lists: IPageListParams[],
    index: number,
    pageActive: string,
  ): Promise<void> {
    const termsConditions = await this.selectTermsandConditions$
      .pipe(take(1))
      .toPromise();
    if (termsConditions.data === null) {
      this.singupFacade.getTermandConditions();
      const res = await this.selectTermsandConditions2$
        .pipe(take(2))
        .toPromise();
      if (res.data) {
        lists = this.setListState(lists, index, pageActive);
      } else if (res.error) {
        this.navigatetoError();
      }
    } else {
      lists = this.setListState(lists, index, pageActive);
    }
  }

  async processHabeasData(
    lists: IPageListParams[],
    index: number,
    pageActive: string,
  ): Promise<void> {
    const habeasData = await this.selectHabeasData$.pipe(take(1)).toPromise();
    if (habeasData.data === null) {
      this.singupFacade.getHabeasData();
      const res = await this.selectHabeasData2$.pipe(take(2)).toPromise();
      if (res.data) {
        lists = this.setListState(lists, index, pageActive);
      } else if (res.error) {
        this.navigatetoError();
      }
    } else {
      lists = this.setListState(lists, index, pageActive);
    }
  }

  /**
   * Method to set state to list and return lists values
   *
   * @param lists list to return info state
   * @param index position of list to set state
   * @param next param to navigate if each state is true
   */
  setListState(
    lists: IPageListParams[],
    index: number,
    next: string,
  ): IPageListParams[] {
    lists[index].state = true;
    let flag = true;
    lists.forEach((list: IPageListParams) => {
      if (list.state === false) {
        flag = false;
      }
    });
    if (flag === true) {
      this.appFacade.setLoaderHide();
      this.router.navigate([`/signup/${next}`]);
    }
    return lists;
  }
  /**
   * set event to load Habeas Data
   */
  get selectHabeasData$(): Observable<IHabeasData> {
    return this.singupFacade.selectHabeasData$;
  }
  /**
   * set event to load Terms and Conditiions
   */
  get selectTermsandConditions$(): Observable<ITermsandConditions> {
    return this.singupFacade.selectTermsandConditions$;
  }
  /**
   * set event to load Habeas Data 2
   */
  get selectHabeasData2$(): Observable<IHabeasData> {
    return this.singupFacade.selectHabeasData2$;
  }
  /**
   * set event to load Terms and Conditiions 2
   */
  get selectTermsandConditions2$(): Observable<ITermsandConditions> {
    return this.singupFacade.selectTermsandConditions2$;
  }
}

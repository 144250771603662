import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomHttpParams } from "@app/helpers/custom-http-params";
import { environment } from "@environment";
import { Observable } from "rxjs";
import {
  IVehiclesListRequest,
  IVehiculeCreation,
  IDeleteVehicleRequest,
} from "../models/vehicle";

/**
 * Injectable
 */
@Injectable({
  providedIn: "root",
})
export class VehiclesService {
  constructor(private http: HttpClient) {}
  /**
   * Endpoint to get vehicles categories
   */
  getVehiclesCategories(): Observable<any> {
    return this.http.get(
      environment.api_key.publicPN.back_url +
        environment.endpoints.vehicles.init +
        environment.endpoints.vehicles.specific.categories +
        "?category_enabled=true",
      {
        params: new CustomHttpParams("api-pn-public-apiKey"),
      },
    );
  }
  /**
   * end point to create vehicle
   *
   * @param data vehicle data
   */
  postVehicle(data: IVehiculeCreation): Observable<any> {
    return this.http.post(
      environment.api_key.vehicles.back_url +
        environment.endpoints.vehicles.init +
        environment.endpoints.vehicles.specific.create,
      data,
      {
        params: new CustomHttpParams("api-pn-vehicles-apiKey-token-no-ctype"),
      },
    );
  }
  /**
   * get Vehicles List
   *
   * @param data vehicle List Request Data
   */
  getVehiclesList(data: IVehiclesListRequest): Observable<any> {
    let params = [
      data.licensePlate ? `licensePlate=${data.licensePlate}` : "",
      data.personId ? `personId=${data.personId.split("-")[0]}` : "",
      data.pageSize ? `limit=${30}` : "",
    ].filter((param) => param !== "");

    let queryString = params.join("&");
    return this.http.get(
      `${environment.api_key.vehicles.back_url}${environment.endpoints.vehicles.v2}${environment.endpoints.vehicles.specific.vehicles}?${queryString}`,
      {
        params: new CustomHttpParams("api-gateway-vehicles-v2"),
      },
    );
  }
  /**
   *
   * @param data IDeleteVehicleRequest
   * @returns Observable
   */
  deleteVehicle(data: IDeleteVehicleRequest): Observable<any> {
    return this.http.post(
      environment.api_key.vehicles.back_url +
        environment.endpoints.vehicles.initManagement +
        environment.endpoints.vehicles.specific.deleteManagement,
      data,
      {
        params: new CustomHttpParams("api-token-x-client-id"),
      },
    );
  }
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomHttpParams } from "@app/helpers/custom-http-params";
import { environment } from "@environment";
import { Observable } from "rxjs";
import { ITagActivation } from "../models/tag-activation";
/**
 * Injectable
 */
@Injectable({
  providedIn: "root",
})
export class ActivationTagService {
  /**
   * Constructor
   *
   * @param http http Client
   */
  constructor(private http: HttpClient) {}
  /**
   * postTagActivation
   *
   * @param tagActivationData activactionData
   */
  postTagActivation(tagActivationData: ITagActivation): Observable<any> {
    return this.http.post(
      `${environment.api_key.transerversal_services.back_url}${environment.endpoints.core.init}` +
        `${environment.endpoints.core.tags}${environment.endpoints.core.specific.activation}`,
      tagActivationData,
      {
        params: new CustomHttpParams("api-token-x-client-id"),
      },
    );
  }
}

import { Injectable } from "@angular/core";
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { IUser } from "@app/models/user";
import { CommonsService } from "@app/modules/shared/services/commons.service";
import { AuthenticationService } from "@singup/services/authentication.service";
import { from, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ActiveSessionGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private commonsService: CommonsService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    return from(this.verifyToken(state.url)); // Pasamos la URL actual
  }

  async verifyToken(currentUrl: string): Promise<boolean> {
    let userCognito: IUser;
    let token = "";

    userCognito =
      await this.authenticationService.getCurrentAuthenticatedUser();
    if (userCognito) {
      token = userCognito.data.authenticationResult.idToken;
    }

    // Si no hay token o está expirado, permite el acceso
    if (!token || this.authenticationService.tokenExpired(token)) {
      return true;
    }

    // Usuario autenticado, validar política
    const policyEnabled = this.commonsService.getPolicyEnabled(); // Asume que retorna un Observable

    if (!policyEnabled) {
      const targetRoute = `/data-policy/new-policy`;
      if (currentUrl !== targetRoute) {
        this.router.navigate(["data-policy", "new-policy"]); // Redirige a new-policy
        return false;
      }
      return true; // Permite permanecer en new-policy
    } else {
      this.router.navigate(["home"]); // Política aceptada, redirige al home
      return false;
    }
  }
}

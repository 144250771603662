import { IAccount } from "@app/models/all-data";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { accountsFeatureName } from "../state/accounts.state";

export const AccountsRootSelector =
  createFeatureSelector<IAccount>(accountsFeatureName);

export const selectAccounts = createSelector(
  AccountsRootSelector,
  (state: IAccount) => state,
);

import { IPages } from "@app/models/pages";
import { vehiclesPagesConst } from "@core/constants/pages.constant";
import {
  IVehicleInformation,
  IVehiclesCategories,
  IVehiclesList,
} from "@vehicles/models/vehicle";

/**
 * Set a Name to read chart redux
 */
export const VehiclesFeatureName = "VehicleState";

/**
 * Grouping to VehiclesState
 */
export type VehiclesModuleState = {
  vehiclesCategoriesReducer: IVehiclesCategories;
  vehicleInformationReducer: IVehicleInformation;
  vehiclePagesReducer: IPages;
  vehicleListReducer: IVehiclesList;
};

export const initialStateVehiclesListReducer: IVehiclesList = {
  data: null,
  error: null,
  loaded: null,
  loading: null,
};
/**
 * Initialize state to Vehicle types
 */
export const initialStateVehiclesCategoriesReducer: IVehiclesCategories = {
  data: null,
  error: null,
  loaded: null,
  loading: null,
};
/**
 * Initialize state to Vehicle plate
 */
export const initialStateVehicleInformationReducer: IVehicleInformation = {
  licensePlate: null,
  vehicleType: null,
  accountId: null,
};

export const initialStateVehiclePages: IPages = {
  pages: [vehiclesPagesConst[0]],
};

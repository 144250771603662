import { HttpClient } from "@angular/common/http";
import {
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { AppFacade } from "@app/app.facade";
import { IMovementsState } from "@app/models/accounts";
import { IAccount, ILogin, ILoginsData, IUserInfo } from "@app/models/all-data";
import { IClientInfo, ICustomerInfo } from "@app/models/customer-info";
import { initialModulesResetState } from "@app/store/state/modules.state";
import { TranslateService } from "@ngx-translate/core";
import { CommonsService } from "@shared/services/commons.service";
import { StorageService } from "@shared/services/storage.service";
import { Languages } from "@store/actions/app.action";
import {
  IVehicleInformation,
  IVehiclesCategories,
  IVehiclesCategoriesResponse,
  IVehiclesList,
  IVehiclesListResponse,
} from "@vehicles/models/vehicle";
import { Observable, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { MovementsService } from "../movements/services/movements.service";
import { AuthenticationService } from "../singup/services/authentication.service";
import { VehiclesFacade } from "../vehicles/store/facade/vehicles.facade";
import { IDashboardInfo } from "./models/home";
import { IPublcKeyResponse } from "./models/pubicKey";
import { DashboardService } from "./services/dashboard.service";
/**
 * Component
 */
@Component({
  selector: "fp-home",
  styleUrls: ["./home.component.scss"],
  templateUrl: "./home.component.html",
})
export class HomeComponent implements OnInit, OnDestroy {
  documentType: any;
  dashboardInfo: IDashboardInfo;
  destroy$: Subject<boolean> = new Subject<boolean>();
  loading: boolean = true;
  messageError: string;
  error: boolean;
  clientInfo: IClientInfo;
  userInfo: any;
  addressInfo: any;
  vehiclesState: IVehiclesList;
  @ViewChild("tempMessageError", { static: true })
  tempMessageError: TemplateRef<any>;
  accountsState: IAccount;
  username: string = "";
  movementsState: IMovementsState;
  accountType = "";
  visibleSnack = true;
  bannerLink: any;
  loginData: ILoginsData;
  itemVehicleSelected: IVehiclesListResponse;
  loadingChange: boolean = true;
  categories: IVehiclesCategoriesResponse[];
  /**
   * Constructor
   *
   * @param appFacade AppFacade
   * @param dashboardService DashboardService
   * @param router Router
   * @param storageService StorageService
   * @param commonService CommonsService
   * @param modalService ModalService
   * @param snackBar MatSnackBar
   * @param translate TranslateService
   * @param vehiclesFacade
   * @param authenticationService
   * @param movementsService
   */
  constructor(
    private appFacade: AppFacade,
    private dashboardService: DashboardService,
    private router: Router,
    private storageService: StorageService,
    private commonService: CommonsService,
    private snackBar: MatSnackBar,
    public translate: TranslateService,
    private vehiclesFacade: VehiclesFacade,
    private authenticationService: AuthenticationService,
    private movementsService: MovementsService,
    private http: HttpClient,
  ) {}
  /**
   * Destroy components
   */
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.closeMessage();
  }
  /**
   * get Initial State
   */
  async ngOnInit(): Promise<any> {
    this.appFacade.getAllData();
    this.appFacade.setModules(initialModulesResetState);
    document.body.classList.remove("no-overflow");
    this.selectLanguage$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (value) {
        this.changeLanguage(value);
        this.translate.use(value);
      }
    });
    this.username = await this.authenticationService.getUsername();
    this.getUserInfo();
    this.getAccounts();
    this.selectVehiclesList();
    this.selectVehiclesCategories();

    this.selectLoginsData$.pipe(take(2)).subscribe((log: ILogin) => {
      this.loginData = log.data;
    });

    this.http.get("assets/i18n/home/es.json").subscribe((data: any) => {
      this.bannerLink = data.bannerLink;
    });
  }

  async getAccounts(): Promise<void> {
    this.selectAccounts$
      .pipe(takeUntil(this.destroy$))
      .subscribe((action: IAccount) => {
        this.handleAccountAction(action);
      });
  }

  async handleAccountAction(action: IAccount): Promise<void> {
    this.accountsState = action;
    if (action.data) {
      this.accountType = action.data[0].accountType;
      await this.storageService.setItem(
        "accountId",
        action.data[0].accountId.toString(),
      );
    }
  }

  /**
   * getMovements from Redis - Do not delete
   *
   * @param userInfo
   */
  async getMovements(userInfo: any = null): Promise<any> {
    this.movementsState = {
      data: null,
      error: null,
      loaded: false,
      loading: false,
    };
    try {
      const personId = userInfo.id;
      const account = userInfo.accounts[0];
      const infoMovements = await this.movementsService
        .getMovementsDashboard({ personId, account })
        .toPromise();
      this.movementsState.data = infoMovements.body.sort((a, b) =>
        b.dateTransaction.localeCompare(a.dateTransaction),
      );
    } catch ({ error }) {
      if (error?.data?.code === "100773") {
        this.movementsState.data = [];
      } else {
        this.movementsState.error = error;
      }
    } finally {
      this.loading = false;
    }
  }

  /**
   * get Dashboard Info
   */
  async getDashboardInfo(): Promise<any> {
    try {
      const dashboardInfoResponse = await this.dashboardService
        .getDashboardInfo()
        .toPromise();
      this.dashboardInfo = dashboardInfoResponse.data.dashboardInfo;
      if (
        this.dashboardInfo.personInfo.length === 0 ||
        this.dashboardInfo.personInfo[0].code
      ) {
        this.router.navigate(["/error"]);
      }
      this.loading = false;
      await this.storageService.setItem(
        "accountId",
        this.dashboardInfo.accounts[0].accountId.toString(),
      );
    } catch (error) {
      this.router.navigate(["/error"]);
    }
  }

  /**
   * get Public Key
   */
  async getPublicKey(): Promise<any> {
    await this.dashboardService
      .getPublicKey()
      .subscribe((pubicKey: IPublcKeyResponse) => {
        this.storageService.setItem("publicKey", JSON.stringify(pubicKey));
      });
  }

  showError(): void {
    this.snackBar.openFromTemplate(this.tempMessageError, {
      horizontalPosition: "center",
      panelClass: "fp-snack-modify",
      verticalPosition: "top",
    });
  }

  /**
   * Close Message
   */
  closeMessage(): void {
    this.snackBar.dismiss();
  }
  /**
   * Change Language
   *
   * @param language language
   */
  changeLanguage(language: Languages): void {
    this.appFacade.changeLanguage(language);
  }

  /**
   * get data of language selector
   */
  get selectLanguage$(): Observable<Languages> {
    return this.appFacade.selectLanguage$;
  }
  /**
   * navigate to Page
   *
   * @param route
   */
  navigatetoRoute(route: string): void {
    this.commonService.navigate(route);
  }
  /**
   * redirect to Buy Tag
   *
   * @param $event Vehicle Information Data
   */
  goTobuyTag($event: IVehicleInformation) {
    this.commonService.redirectToTagWithVehicleInformation($event);
  }

  async validateSelectUserInfo$() {
    let userInfoPromise;
    if (this.selectUserInfo$ !== undefined) {
      userInfoPromise = await this.selectUserInfo$.pipe(take(1)).toPromise();
    }
    return userInfoPromise;
  }

  async getUserInfo(): Promise<void> {
    const userInfoPromise = await this.validateSelectUserInfo$();
    if (userInfoPromise?.data === null) {
      this.selectUserInfo$
        .pipe(takeUntil(this.destroy$))
        .subscribe((action: IUserInfo) => {
          this.handleUserInfoAction(action);
        });
    } else {
      this.userInfo = userInfoPromise.data;
      this.getMovements(this.userInfo);
    }
  }

  async handleUserInfoAction(action: IUserInfo): Promise<void> {
    if (action && action.data !== null) {
      this.userInfo = action.data;
      this.getMovements(this.userInfo);
    }
    if (action && action.error) {
      const attemps: number = await this.commonService.getAttempsErrorServices(
        "userInfo",
      );
      if (attemps > 2) {
        this.commonService.navigate("/signout");
      } else {
        await this.commonService.setAttempsErrorServices("userInfo");
        this.commonService.navigate("error");
      }
    }
  }

  selectVehiclesList(): void {
    this.vehiclesFacade.getVehiclesList(this.username);
    this.selectVehiclesList$
      .pipe(takeUntil(this.destroy$))
      .subscribe((action: IVehiclesList) => {
        this.vehiclesState = JSON.parse(JSON.stringify(action));
        if (action.data != null) {
          const data = JSON.parse(JSON.stringify(action.data));
          this.vehiclesState.data = data.splice(0, 3);
        }
      });
  }

  closeSnack() {
    this.visibleSnack = false;
  }

  goTo() {
    window.open(this.bannerLink, "_blank");
  }

  selectVehiclesCategories(): void {
    this.vehiclesFacade.getVehiclesCategories();
    this.selectVehicleCategory$
      .pipe(takeUntil(this.destroy$))
      .subscribe((listCategory: IVehiclesCategories) => {
        this.categories = listCategory.data;
      });
  }

  get selectCustomerInfo$(): Observable<ICustomerInfo> {
    return this.appFacade.selectCustomerInfo$;
  }

  get selectUserInfo$(): Observable<IUserInfo> {
    return this.appFacade.selectUserInfo$;
  }
  /**
   * get accounts from Selector
   */
  get selectAccounts$(): Observable<IAccount> {
    return this.appFacade.selectAccounts$;
  }

  get selectVehiclesList$(): Observable<IVehiclesList> {
    return this.vehiclesFacade.selectVehiclesList$;
  }

  get selectLoginsData$(): Observable<ILogin> {
    return this.appFacade.selectLoginsData$;
  }

  get selectVehicleCategory$(): Observable<IVehiclesCategories> {
    return this.vehiclesFacade.selectVehiclesCategories$;
  }
}

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DataPolicyComponent } from "./data-policy.component";
import { successfulRoute } from "./modules/successful/successful-data-policy-routing.module";

export const DataPolicyRootRoute = "data-policy";
const routes: Routes = [
  {
    component: DataPolicyComponent,
    path: "",
  },
  {
    path: successfulRoute,
    loadChildren: () =>
      import("./modules/successful/successful-data-policy.module").then(
        (m) => m.SuccessfulDataPolicyModule,
      ),
  },
  {
    path: "new-policy",
    loadChildren: () =>
      import("./modules/new-policy/new-policy.module").then(
        (m) => m.NewPolicyModule,
      ),
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)],
})
export class DataPolicyRoutingModule {}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CustomHttpParams } from "@app/helpers/custom-http-params";
import { IAllData } from "@app/models/all-data";
import { environment } from "@environment";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DataPolicyService {
  constructor(private http: HttpClient) {}

  registerUser(data: any): Observable<any> {
    return this.http.post(
      environment.api_key.services.back_url +
        environment.endpoints.analyticEvents.init +
        environment.endpoints.analyticEvents.specific.analytic,
      data,
      {
        params: new CustomHttpParams("api-key-event-analytic"),
      },
    );
  }

  updateDataPolicy(request: IAllData): Observable<any> {
    return this.http.post<IAllData>(
      environment.api_key.services.back_url +
        environment.endpoints.core.init +
        environment.endpoints.core.specific.customer +
        environment.endpoints.core.specific.update,
      request,
      {
        params: new CustomHttpParams("api-gateway-vehicles-v2"),
      },
    );
  }
}

import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { selectAccounts } from "@store/selectors/accounts.selector";
import { selectCities } from "@store/selectors/cities.selector";
import { selectCustomerInfo } from "@store/selectors/customer-info.selector";
import { selectLoader } from "@store/selectors/loader.selector";
import { selectModules } from "@store/selectors/modules.selector";
import { selectUserInfo } from "@store/selectors/user-info.selector";
import { IIdentifiTypesState } from "@store/state/app.state";
import { Observable, Subject } from "rxjs";
import { IAccount, IAllData, ILogin, IUserInfo } from "./models/all-data";
import { ICustomerInfo } from "./models/customer-info";
import { ILoaderState } from "./models/loader";
import { IModules } from "./models/modules";
import { ICities } from "./modules/shared/models/cities";
import { IAddress } from "./modules/tag/models/address";
import * as allDataActions from "./store/actions/all-data.actions";
import {
  changeLanguage,
  Languages,
  resetDataStore,
} from "./store/actions/app.action";
import * as citiesActions from "./store/actions/cities.actions";
import * as customerInfoActions from "./store/actions/customer-info.actions";
import * as documentTypeActions from "./store/actions/identification-types.action";
import * as loaderActions from "./store/actions/loader.actions";
import * as modulesActions from "./store/actions/modules.actions";
import { IAppState } from "./store/reducers/index.app.reducer";
import { selectAddress } from "./store/selectors/address.selector";
import { selectAllData } from "./store/selectors/all-data.selector";
import {
  selectIdentificationTypes,
  selectLanguage,
} from "./store/selectors/app.selector";
import { selectLoginsData } from "./store/selectors/logins.selector";

@Injectable()
export class AppFacade {
  destroy$: Subject<boolean> = new Subject<boolean>();

  public selectLanguage$: Observable<Languages> = this.store.pipe(
    select(selectLanguage),
  );

  public selectIdentificationType$: Observable<IIdentifiTypesState> =
    this.store.pipe(select(selectIdentificationTypes));

  public selectIdentificationType2$: Observable<IIdentifiTypesState> =
    this.store.pipe(select(selectIdentificationTypes));

  public selectCities$: Observable<ICities> = this.store.pipe(
    select(selectCities),
  );

  public selectCities2$: Observable<ICities> = this.store.pipe(
    select(selectCities),
  );

  public selectLoader$: Observable<ILoaderState> = this.store.pipe(
    select(selectLoader),
  );

  public selectModules$: Observable<IModules> = this.store.pipe(
    select(selectModules),
  );

  public selectAccounts$: Observable<IAccount> = this.store.pipe(
    select(selectAccounts),
  );
  public selectAccounts2$: Observable<IAccount> = this.store.pipe(
    select(selectAccounts),
  );

  public selectCustomerInfo$: Observable<ICustomerInfo> = this.store.pipe(
    select(selectCustomerInfo),
  );
  public selectCustomerInformation$: Observable<ICustomerInfo> =
    this.store.pipe(select(selectCustomerInfo));

  public selectUserInfo$: Observable<IUserInfo> = this.store.pipe(
    select(selectUserInfo),
  );
  public selectUserInfo2$: Observable<IUserInfo> = this.store.pipe(
    select(selectUserInfo),
  );

  public selecAddress$: Observable<IAddress> = this.store.pipe(
    select(selectAddress),
  );
  public selecAddress2$: Observable<IAddress> = this.store.pipe(
    select(selectAddress),
  );

  public selectLoginsData$: Observable<ILogin> = this.store.pipe(
    select(selectLoginsData),
  );

  public selectAllData$: Observable<IAllData> = this.store.pipe(
    select(selectAllData),
  );

  constructor(private store: Store<IAppState>) {}
  /**
   * get Identification Types
   */
  public getIdentificationTypes(): void {
    this.store.dispatch(documentTypeActions.documentTypeLoad());
  }
  /**
   * change language
   *
   * @param language language
   */
  changeLanguage(language: Languages): void {
    this.store.dispatch(changeLanguage({ language }));
  }
  /**
   * get Cities
   */
  public getCities(): void {
    this.store.dispatch(citiesActions.citiesLoad());
  }
  /**
   * set event to show loader
   *
   * @param loaderObj loader Object
   */
  public setLoaderShow(loaderObj: ILoaderState): void {
    this.store.dispatch(loaderActions.loaderShow(loaderObj));
  }
  /**
   * set event to hide loader
   */
  public setLoaderHide(): void {
    this.store.dispatch(loaderActions.loaderHide());
  }
  /**
   * Set modules
   *
   * @param modules modules
   */
  public setModules(modules: IModules): void {
    this.store.dispatch(modulesActions.tagSetModules(modules));
  }

  public resetDataStore() {
    this.store.dispatch(resetDataStore());
  }

  public getCustomerInfo(): void {
    this.store.dispatch(customerInfoActions.customerInfoLoad());
  }

  public getAllData(): void {
    this.store.dispatch(allDataActions.loadAllData());
  }
}

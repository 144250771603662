import {
  Component,
  EventEmitter,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AppFacade } from "@app/app.facade";
import { ITagInfo, IVehiclesInfo } from "@app/modules/home/models/home";
import { ITagActivation } from "@app/modules/shared/models/tag-activation";
import { CommonsService } from "@app/modules/shared/services/commons.service";
import { ModalService } from "@app/modules/theme/components/modal/service/modal.service";
import {
  IDeleteVehicleRequest,
  IVehicleInformation,
  IVehiclesCategoriesResponse,
  IVehiclesListResponse,
} from "../../models/vehicle";
import { VehiclesService } from "../../services/vehicle.service";

@Component({
  selector: "fp-vehicles-management-container",
  templateUrl: "./vehicles-management-container.component.html",
  styleUrls: ["./vehicles-management-container.component.scss"],
})
export class VehiclesManagementContainerComponent {
  @ViewChild("tempError", { static: true })
  tempMessageError: TemplateRef<any>;
  @Input() vehicleInfo: IVehiclesInfo;
  @Input() vehicle: IVehiclesInfo;
  @Input() vehicles: IVehiclesListResponse[];
  @Input() categories: IVehiclesCategoriesResponse[];
  @Input() typeTemplate: string = "";
  @Output() changeStatusResponse = new EventEmitter();

  category: string;
  loadingChange: boolean = false;
  showDeleteModal: boolean = false;
  showActivationModal: boolean = false;
  vehicleInfoToDelete: IVehiclesInfo;
  titleModal: string;
  labelBtnAccept: string;
  hideClose: boolean;
  itemVehicleSelected: IVehiclesListResponse;
  messageError: string;
  error: boolean = false;

  constructor(
    private modalService: ModalService,
    private commonsService: CommonsService,
    private appFacade: AppFacade,
    private vehiclesService: VehiclesService,
    private snackBar: MatSnackBar,
  ) {}

  /**
   * Change Status on tag
   *
   * @param tagId tag Id
   */
  async onChangeStatus(tagId: ITagInfo): Promise<any> {
    this.loadingChange = true;
    let status: "ACTIVE" | "INACTIVE";
    if (tagId.currentStatus !== 1) {
      status = "ACTIVE";
      this.messageError = "error_message.activate";
    } else {
      status = "INACTIVE";
      this.messageError = "error_message.inactive";
    }
    try {
      const infoActivation: ITagActivation = {
        personId: tagId.idClient,
        tagId: tagId.id,
        status,
      };

      const respActivateTag =
        await this.commonsService.callTagActivationService(infoActivation);
      if (!respActivateTag.error) {
        if (tagId.currentStatus !== 1) {
          this.titleModal = "modal.title";
          this.labelBtnAccept = "modal.btn";
          this.hideClose = false;
        } else {
          this.titleModal = "modal.title_inactive";
          this.labelBtnAccept = "modal.btn_inactive";
          this.hideClose = true;
        }
        this.modalService.open("fp-activation-progress");
        this.changeStatusResponse.emit();
      } else {
        this.error = true;
        this.showError();
      }
    } catch {
      this.error = true;
      this.showError();
    } finally {
      this.modalService.close("fp-vehicles-management", false);
      this.loadingChange = false;
    }
  }

  openModalManagement($event: boolean, itemVehicle: IVehiclesListResponse) {
    if ($event) {
      this.openModal("fp-vehicles-management");
      this.itemVehicleSelected = itemVehicle;
    }
  }

  openModal(id: string): void {
    this.closeAlertActive();
    this.modalService.open(id);
  }

  onCloseModal(id: string): void {
    this.modalService.close(id, false);
  }

  goTobuyTag($event: IVehicleInformation) {
    this.commonsService.redirectToTagWithVehicleInformation($event);
  }

  /**
   * navigate to Recharge
   */
  navigatetoRecharge(): void {
    this.commonsService.navigate("/transactions/recharge");
  }

  async onDeleteVehicle($event: {
    payload: IDeleteVehicleRequest;
    typeAction: string;
  }) {
    this.onCloseModal("fp-delete-vehicle");
    if ($event.typeAction === "accept") {
      try {
        this.appFacade.setLoaderShow({
          type: "GENERAL",
        });
        await this.vehiclesService.deleteVehicle($event.payload).toPromise();
        this.changeStatusResponse.emit();
      } catch (error) {
        this.messageError =
          "No fue posible eliminar el vehiculo, inténtelo más tarde";
        this.showError();
      } finally {
        this.appFacade.setLoaderHide();
      }
    }
  }

  /**
   * show Error
   */
  showError(): void {
    this.snackBar.openFromTemplate(this.tempMessageError, {
      horizontalPosition: "center",
      panelClass: "fp-snack-modify",
      verticalPosition: "top",
    });
  }

  deleteVehicleShowModal($event: IVehiclesInfo): void {
    this.vehicleInfoToDelete = $event;
    this.category = this.categories.find(
      ({ shortDescription }) =>
        shortDescription === $event.vehicle.shortDescriptionCategory,
    ).description;
    this.modalService.open("fp-delete-vehicle");
    this.onCloseModal("fp-vehicles-management");
  }

  closeAlertActive(): void {
    this.snackBar.dismiss();
  }
}

import { registerLocaleData } from "@angular/common";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import localeCOP from "@angular/common/locales/es-CO";
import { InjectionToken, LOCALE_ID, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { JwtModule } from "@auth0/angular-jwt";
import { environment } from "@environment";
import { EffectsModule } from "@ngrx/effects";
// Ngrx
import { ActionReducerMap, StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
// i18n
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { SingUpFacade } from "@singup/store/facade/singup.facade";
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AppFacade } from "./app.facade";
import { windowProvider, WindowToken } from "./helpers/windows.helpers";
import { CoreModule } from "./modules/core/core.module";
import { ThemeModule } from "./modules/theme/theme.module";
import { NGFORAGE_CONFIG_PROVIDER } from "./ngforage.config";
import { AccountsEffect } from "./store/effects/accounts.effects";
import { AddressEffect } from "./store/effects/address.effects";
import { AllDataEffect } from "./store/effects/all-data.effects";
import { CitiesEffect } from "./store/effects/cities.effects";
import { CustomerInfoEffect } from "./store/effects/customer-info.effects";
import { IdentificationTypesEffect } from "./store/effects/identification-types.effect";
import { UserInfoEffect } from "./store/effects/user-info.effects";
import { metaReducers } from "./store/hydration/index.reducer";
import { AppRootReducer, IAppState } from "./store/reducers/index.app.reducer";

registerLocaleData(localeCOP);

/**
 * @param http
 */
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
/**
 *
 */
export function tokenGetter() {
  return null;
}

export const AppRootReducerToken = new InjectionToken<
  ActionReducerMap<IAppState>
>("Feature App Component");

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    StoreModule.forRoot(AppRootReducerToken, {
      metaReducers,
      runtimeChecks: {
        strictActionImmutability: true,
        strictStateImmutability: true,
      },
    }),
    EffectsModule.forRoot([
      IdentificationTypesEffect,
      CitiesEffect,
      AccountsEffect,
      CustomerInfoEffect,
      UserInfoEffect,
      AddressEffect,
      AllDataEffect,
    ]),
    StoreDevtoolsModule.instrument({
      logOnly: environment.production,
      maxAge: 25,
    }),
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: "es",
      loader: {
        deps: [HttpClient],
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
      },
      isolate: true,
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter,
      },
    }),
    CoreModule,
    ThemeModule,
    BrowserAnimationsModule,
    RecaptchaV3Module,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "es-co" },
    AppFacade,
    {
      provide: AppRootReducerToken,
      useValue: AppRootReducer,
    },
    { provide: WindowToken, useValue: windowProvider },
    NGFORAGE_CONFIG_PROVIDER,
    SingUpFacade,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.keyngCache,
    },
  ],
})
export class AppModule {}

import { Injectable } from "@angular/core";
import { IPage, IPages } from "@app/models/pages";
import { select, Store } from "@ngrx/store";
import {
  IVehicleInformation,
  IVehiclesCategories,
  IVehiclesList,
} from "@vehicles/models/vehicle";
import * as vehicleActions from "@vehicles/store/actions";
import { Observable } from "rxjs";
import {
  selectVehiclesCategories,
  selectVehiclesInformation,
} from "../selectors/vehicles-categories.selector";
import { selectVehiclesList } from "../selectors/vehicles-list.selector";
import { selectVehiclepages } from "../selectors/vehicles-pages.selector";
import { VehiclesModuleState } from "../state/vehicles.state";
@Injectable({
  providedIn: "root",
})
export class VehiclesFacade {
  constructor(private store: Store<VehiclesModuleState>) {}

  public selectVehiclesCategories$: Observable<IVehiclesCategories> =
    this.store.pipe(select(selectVehiclesCategories));

  public selectVehiclesCategories2$: Observable<IVehiclesCategories> =
    this.store.pipe(select(selectVehiclesCategories));

  public selectVehiclesList$: Observable<IVehiclesList> = this.store.pipe(
    select(selectVehiclesList),
  );

  public selectVehiclesList2$: Observable<IVehiclesList> = this.store.pipe(
    select(selectVehiclesList),
  );

  public selectVehiclesInformation$: Observable<IVehicleInformation> =
    this.store.pipe(select(selectVehiclesInformation));

  public selectVehiclePages$: Observable<IPages> = this.store.pipe(
    select(selectVehiclepages),
  );

  setvehiclePages(pages: IPage[]) {
    this.store.dispatch(vehicleActions.vehicleSetPage(pages));
  }

  getVehiclesCategories() {
    this.store.dispatch(vehicleActions.vehiclesCategoriesLoad());
  }
  getVehiclesList(personId: string) {
    this.store.dispatch(
      vehicleActions.vehiclesListLoad({ personId, pageSize: 20 }),
    );
  }

  setVehicleInformation(vehicleInformation: IVehicleInformation) {
    this.store.dispatch(
      vehicleActions.setVehicleInformation(vehicleInformation),
    );
  }

  public vehicleListReset() {
    this.store.dispatch(vehicleActions.vehiclesListReset());
  }
}

import { Component, Input, OnInit } from "@angular/core";
import { IMovements } from "@home/models/home";

@Component({
  selector: "fp-item-list-movement",
  templateUrl: "./item-list-movement.component.html",
  styleUrls: ["./item-list-movement.component.scss"],
})
export class ItemListMovementComponent implements OnInit {
  @Input() showDivider: boolean = true;
  @Input() typeTemplate: string = "firstTemplate";
  expanded: boolean = false;
  @Input() movement: IMovements;
  transactionClass = "purchase";
  image = "assets/images/movement/ajuste-peaje.svg";
  detail: string;
  signo: string = "-";

  constructor() {}

  ngOnInit(): void {
    let type = this.movement.transactionType;
    switch (type) {
      case "1":
        let description: string = "";
        if (this.movement.data.faceId !== null) {
          description = " ****" + this.movement.data.faceId.substring(-3);
        }
        this.detail = "TAG" + description;
        break;
      case "2":
        this.transactionClass = "recharge";
        this.image = "assets/images/movement/recharge.svg";
        this.detail = this.movement.agreementName.toUpperCase();
        this.signo = "";
        break;
      case "3":
        this.detail = this.movement.station.name;
        break;
      case "4":
        this.transactionClass = "recharge";
        this.image = "assets/images/movement/recharge.svg";
        this.detail = this.movement.station.name;
        this.signo = "";
        break;
      case "5":
        this.detail = this.movement.station.name;
        break;
      case "6":
        this.transactionClass = "recharge";
        this.image = "assets/images/movement/recharge.svg";
        this.signo = "";
        this.detail = this.movement.agreementName;
        break;
      case "8":
        this.transactionClass = "recharge";
        this.image = "assets/images/movement/recharge.svg";
        this.signo = "";
        this.detail = this.movement.agreementName;
        break;
      case "10":
        this.transactionClass = "recharge";
        this.image = "assets/images/movement/recharge.svg";
        this.signo = "";
        this.detail = this.movement.agreementName;
        break;
      case "11":
        this.detail = this.movement.agreementName;
        break;
      case "12":
        let description12: string = "";
        if (this.movement.data.faceId !== null) {
          description12 = " ****" + this.movement.data.faceId.substring(-3);
        }
        this.detail = "TAG" + description12;
        break;
      case "13":
        let description13: string = "";
        if (this.movement.data.faceId != null) {
          description13 = " ****" + this.movement.data.faceId.substring(-3);
        }
        this.detail = "TAG" + description13;
        break;
      case "14":
        this.transactionClass = "recharge";
        this.image = "assets/images/movement/ajuste-peaje.svg";
        this.signo = "";
        this.detail = this.movement.agreementName;
        break;
      case "15":
        this.detail = this.movement.agreementName.toUpperCase();
        break;
      case "16":
        this.detail = this.movement.agreementName.toUpperCase();
        break;
      default:
        this.detail = this.movement.agreementName;
        break;
    }
  }

  showDetail(): void {
    this.expanded = !this.expanded;
  }
}

import { Component, Input, OnInit } from "@angular/core";
import { IFinancialProduct } from "@app/models/all-data";

@Component({
  selector: "fp-automatic-movements",
  templateUrl: "./automatic-movements.component.html",
  styleUrls: ["./automatic-movements.component.scss"],
})
export class AutomaticMovementsComponent {
  @Input() financialProduct: IFinancialProduct;
  transactionClass = "purchase";
  image = "assets/images/movement/recarga-automatica.svg";

  constructor() {}
}

import {
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AppFacade } from "@app/app.facade";
import { TranslateService } from "@ngx-translate/core";
import { CommonsService } from "@shared/services/commons.service";
import { AuthenticationService } from "@singup/services/authentication.service";
import { Languages } from "@store/actions/app.action";
import {
  IVehicleInformation,
  IVehiclesList,
  IVehiclesListResponse,
} from "@vehicles/models/vehicle";
import { VehiclesFacade } from "@vehicles/store/facade/vehicles.facade";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { IVehiclesInfo } from "../../../home/models/home";
import {
  IVehiclesCategories,
  IVehiclesCategoriesResponse,
} from "../../models/vehicle";
@Component({
  selector: "fp-list-vehicles",
  templateUrl: "./list-vehicle.component.html",
  styleUrls: ["./list-vehicle.component.scss"],
})
export class ListComponent implements OnInit, OnDestroy {
  @HostBinding("class") fpListVehicles = "fp-list-vehicles";
  @ViewChild("alertActive", { static: true }) alertActive: TemplateRef<any>;
  loading: boolean = true;
  vehicles: IVehiclesListResponse[];
  categories: IVehiclesCategoriesResponse[];
  destroy$: Subject<boolean> = new Subject<boolean>();
  titleModal: string = "modal.title";
  labelBtnAccept: string = "modal.btn";
  hideClose: boolean;
  messageError: string;
  itemVehicleSelected: IVehiclesListResponse;
  vehicleInfo: IVehiclesInfo;

  constructor(
    private snackBar: MatSnackBar,
    private commonsService: CommonsService,
    private vehiclesFacade: VehiclesFacade,
    private authenticationService: AuthenticationService,
    public translate: TranslateService,
    private appFacade: AppFacade,
  ) {}
  /**
   * destroy subscriptions
   */
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.closeAlertActive();
    this.vehiclesFacade.vehicleListReset();
  }
  /**
   * ngOnInit to start initial values
   */
  ngOnInit(): void {
    this.selectLanguage$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (value) {
        this.translate.use(value);
      }
    });
    this.getVehicles();
    this.snackBar.openFromTemplate(this.alertActive, {
      horizontalPosition: "center",
      panelClass: "fp-snack-modify",
      verticalPosition: "top",
    });
    this.selectVehiclesCategories();
    this.selectVehiclesList();
  }

  /**
   * Close Message
   */
  closeAlertActive(): void {
    this.snackBar.dismiss();
  }
  /**
   * get Vehicles List
   *
   * @param flag
   */
  async getVehicles(flag: boolean = false): Promise<any> {
    if (flag) {
      this.loading = true;
    }
    this.vehiclesFacade.getVehiclesList(
      await this.authenticationService.getUsername(),
    );
  }
  /**
   * select Vehicle list from state
   */
  selectVehiclesList(): void {
    this.selectVehiclesList$
      .pipe(takeUntil(this.destroy$))
      .subscribe((listVehicles: IVehiclesList) => {
        if (listVehicles.data && !listVehicles.loading) {
          this.loading = listVehicles.loading;
          this.vehicles = listVehicles.data;
        }
        if (listVehicles.error) {
          this.loading = listVehicles.loading;
        }
      });
  }

  selectVehiclesCategories(): void {
    this.selectVehicleCategory$
      .pipe(takeUntil(this.destroy$))
      .subscribe((listCategory: IVehiclesCategories) => {
        this.categories = listCategory.data;
      });
  }
  /**
   * navigatetoVehicleCreation
   */
  navigatetoVehicleCreation(): void {
    this.commonsService.navigate("/vehicle/create");
  }

  /**
   * redirect to Buy tag form
   *
   * @param $event event with vehicle Information
   */
  goTobuyTag($event: IVehicleInformation) {
    this.commonsService.redirectToTagWithVehicleInformation($event);
  }

  changeStatusHandler() {
    this.getVehicles();
    this.selectVehiclesList();
  }

  get selectVehiclesList$(): Observable<IVehiclesList> {
    return this.vehiclesFacade.selectVehiclesList$;
  }

  get selectLanguage$(): Observable<Languages> {
    return this.appFacade.selectLanguage$;
  }

  get selectVehicleCategory$(): Observable<IVehiclesCategories> {
    return this.vehiclesFacade.selectVehiclesCategories$;
  }
}

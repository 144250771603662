import { createAction, props } from "@ngrx/store";
import { IVehicleInformation } from "@vehicles/models/vehicle";

export const enum TypeActionsVehicleInformation {
  vehicleSetInformation = "[VEHICLE INFORMATION] set information",
}
/**
 * Action to set vehicle information
 */
export const setVehicleInformation = createAction(
  TypeActionsVehicleInformation.vehicleSetInformation,
  (vehicleInformation: IVehicleInformation) => ({ vehicleInformation }),
);

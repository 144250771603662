import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { AppFacade } from "@app/app.facade";
import { IAccount } from "@app/models/all-data";
import { CommonsService } from "@app/modules/shared/services/commons.service";
import { ModalService } from "@app/modules/theme/components/modal/service/modal.service";
import { Languages } from "@app/store/actions/app.action";
import { TranslateService } from "@ngx-translate/core";
import dayjs from "dayjs/esm";
import { Observable, Subject } from "rxjs";
import { finalize, take, takeUntil, tap } from "rxjs/operators";
import { ReportService } from "../../services/report.service";
import { RequestReportService } from "../../services/request-report.service";
import { IReport } from "./models/report";

@Component({
  selector: "fp-reports",
  templateUrl: "./reports.component.html",
  styleUrls: ["./reports.component.scss"],
})
export class ReportsComponent implements OnInit, OnDestroy {
  maxDateLimit: dayjs.Dayjs;
  minDateLimit: dayjs.Dayjs;
  dateRange: any;
  months: string[];
  years: number[];
  adviceText: SafeHtml;
  destroy$: Subject<boolean> = new Subject<boolean>();

  reportsList: IReport[] = [];
  reportsPaginator: IReport[] = [];

  reportsState: any = {
    data: [],
    loaded: true,
    loading: false,
    error: false,
  };

  accountId;

  loading: boolean = true;
  loadingReport: boolean = false;

  filters = {
    licensePlate: false,
    numberTag: false,
    infoAditional: false,
    state: false,
    acction: false,
  };

  isMobile = window.innerWidth <= 767;

  constructor(
    private appFacade: AppFacade,
    public translate: TranslateService,
    private sanitizer: DomSanitizer,
    private modalService: ModalService,
    private commonsService: CommonsService,
    private reportService: ReportService,
    private requestReportService: RequestReportService,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  async ngOnInit(): Promise<void> {
    this.selectLanguage$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (value) {
        this.translate.use(value);
        this.setAdviceText(); // Llamamos a la función para traducir y sanitizar el texto
      }
    });
    this.setLimitDates();
    await this.getAccount();
    await this.getReports();
  }

  async getAccount(): Promise<void> {
    this.selectAccounts$
      .pipe(takeUntil(this.destroy$))
      .subscribe((action: IAccount) => {
        this.accountId = action.data[0].accountId;
      });
  }

  requestReport(): void {
    this.loading = true;
    const dateStart = dayjs(this.dateRange.startDate).format("YYYY-MM-DD");
    const dateEnd = dayjs(this.dateRange.endDate).format("YYYY-MM-DD");

    this.requestReportService
      .requestReport(4, "xlsx", dateStart, dateEnd, this.accountId.toString())
      .subscribe({
        next: (response) => {
          this.loading = false;
          this.openModal();
        },
        error: (err) => {
          this.loading = false;
          this.commonsService.navigate("/error");
        },
      });
  }

  selectedDateHandler(payload: any) {
    this.dateRange = payload;
  }

  async getReports(): Promise<void> {
    const accountId = this.accountId;
    this.reportsList = [];
    this.reportsPaginator = [];
    this.loading = true;

    this.reportService
      .getReports(accountId)
      .pipe(
        tap(() => {
          this.loading = true;
        }),
      )
      .subscribe({
        next: (reports) => {
          this.reportsList = reports.body;
          this.reportsPaginator = this.reportsList;
          this.loading = false;
          this.reportsState.error = false;
        },
        error: (error) => {
          this.loading = false;
          this.reportsState.error = true;
        },
      });
  }

  async downloadReport(reportId) {
    let formatReport: string;
    this.loadingReport = true;
    this.reportsPaginator.forEach((reports: any) => {
      if (reports.reportId === reportId) {
        formatReport = reports.formatReport;
      }
    });
    this.requestReportService
      .getUrlReport(reportId, formatReport.toLowerCase())
      .subscribe(
        (response: any) => {
          this.requestReportService
            .getFileReportByURLSign(response.urlReport)
            .subscribe((responseS3: any) => {
              if (responseS3.message === "Reporte permitido para descargar") {
                this.requestReportService
                  .downloadFileBlobByURL(responseS3.body, formatReport)
                  .pipe(
                    take(1),
                    finalize(() => {
                      this.loadingReport = false;
                    }),
                  )
                  .subscribe(
                    (responseFile: any) => {
                      this.requestReportService.downloadReport(
                        responseFile,
                        reportId,
                        formatReport,
                      );
                    },
                    (error: any) => {
                      this.loadingReport = false;
                      return error.error;
                    },
                  );
              } else {
                this.loadingReport = false;
              }
            });
        },
        (error: any) => {
          this.loadingReport = false;
          return error.error;
        },
      );
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: Event) {
    this.isMobile = window.innerWidth <= 767;
  }

  setAdviceText(): void {
    this.translate.get("advice").subscribe((translatedText: string) => {
      this.adviceText = this.sanitizer.bypassSecurityTrustHtml(translatedText);
    });
  }

  openModal(): void {
    this.modalService.open("fp-report-in-process");
  }

  setLimitDates() {
    const date = new Date();
    this.maxDateLimit = dayjs(date);
    date.setMonth(date.getMonth() - 3);
    this.minDateLimit = dayjs(date);
  }

  get selectAccounts$(): Observable<IAccount> {
    return this.appFacade.selectAccounts$;
  }

  get selectLanguage$(): Observable<Languages> {
    return this.appFacade.selectLanguage$;
  }
}

<div class="fp-item-row j-center" [class.card-second]="typeTemplate !== 'firstTemplate'"
    [class.bg-tertiary]="indexEven">
    <div class="fp-item-icon no-tag" *ngIf="!vehicle?.tagEntityConfirmation">
        <img class="" src="assets/images/vehicle/no-tag.svg" alt="sin tag">
    </div>
    <div class="fp-item-icon" [class.no-activeTag]="vehicle?.tagEntityConfirmation.currentStatus !== 1"
        *ngIf="vehicle?.tagEntityConfirmation">
        <img [ngClass]="{'no-activeTag': vehicle?.tagEntityConfirmation.currentStatus !== 1}"
            src="assets/images/vehicle/vehicle.svg" alt="tag activo">
    </div>
    <div class="fp-item-colum texts">
        <div class="fp-item-title plate">
            {{vehicle?.vehicle.licensePlate}}
            <ng-container *ngIf="typeTemplate === 'firstTemplate'">
                <div class="item-title-estate" [ngSwitch]="vehicle?.tagEntityConfirmation?.currentStatus">
                    <span *ngSwitchCase="0">
                        / Inactivo
                    </span>
                    <span *ngSwitchCase="1">
                        / Activo
                    </span>
                    <span *ngSwitchCase="2">
                        / Inactivo
                    </span>
                    <span *ngSwitchDefault>
                        / Sin TAG
                    </span>
                </div>
            </ng-container>

        </div>
        <span class="fp-item-description">
            {{!vehicle?.tagEntityConfirmation? 'TAG pendiente': 'TAG '+vehicle?.tagEntityConfirmation.idFacial}}
        </span>
    </div>
    <button *ngIf="typeTemplate === 'firstTemplate'" class="fp-btn-fap fp-only-mobile" (click)="openModalMoreOptions()">
        <img src="assets/images/vehicle/icon-plus.svg" alt="icono mas opciones">
    </button>
    <ng-container *ngIf="typeTemplate === 'firstTemplate'">
        <div class="item-list-estate fp-only-desktop" [ngSwitch]="vehicle?.tagEntityConfirmation?.currentStatus">
            <span *ngSwitchCase="0">
                Inactivo
            </span>
            <span *ngSwitchCase="1">
                Activo
            </span>
            <span *ngSwitchCase="2">
                Inactivo
            </span>
            <span *ngSwitchDefault>
                Sin TAG
            </span>
        </div>
    </ng-container>
    <div class="fp-desktop-direcction-row" [class.secondTemplate]="typeTemplate === 'secondTemplate'">
        <button *ngIf="!vehicle?.tagEntityConfirmation" class="btn-item-toogle fp-btn-primary" type="submit"
            [class.secondTemplate]="typeTemplate === 'secondTemplate'" id="btn-item-{{vehicle?.vehicle.licensePlate}}"
            (click)="buyTag()" [disabled]="this.tagsActions"
            [matTooltip]="this.tagsActions ? 'Comuniquese con Facilpass' : ''">
            <span class="btn-content-text" id="text-transactions-create-buy">
                Adquirir TAG
            </span>
        </button>
        <button
            [ngClass]="{'fp-btn-tertiary': vehicle?.tagEntityConfirmation.currentStatus !== 1, 'fp-btn-secondary': vehicle?.tagEntityConfirmation.currentStatus === 1}"
            class="btn-item-toogle" [class.secondTemplate]="typeTemplate === 'secondTemplate'"
            *ngIf="vehicle?.tagEntityConfirmation" type="submit" id="btn-item-{{vehicle?.tagEntityConfirmation.id}}"
            (click)="changeStatusTag()" [disabled]="this.tagsActions"
            [matTooltip]="this.tagsActions ? 'Comuniquese con Facilpass' : ''">
            <span class="btn-content-text" id="text-transactions-create" *ngIf="!loading">
                {{ vehicle?.tagEntityConfirmation.currentStatus === 1 ?'Desactivar' : 'Activar'}}
            </span>
            <span class="loader" *ngIf="loading">
            </span>
        </button>
        <div *ngIf="typeTemplate === 'firstTemplate'" style="height: 100%; width: 3.5rem;"></div>
        <div *ngIf="typeTemplate === 'firstTemplate'" class="fp-item-more-options"
            [ngClass]="{'bg-tertiary': indexEven,'bg-white': !indexEven}" [class.show-options]="moreOptions">
            <button class="fp-btn-fap" (click)="toggleMoreOptions()">
                <img *ngIf="!moreOptions" src="assets/images/vehicle/icon-plus.svg" alt="icono mas opciones">
                <img *ngIf="moreOptions" src="assets/images/vehicle/icon-less.svg" alt="icono menos opciones">
            </button>
            <div class="fp-direcction-row toogle-animate" [class.show]="moreOptions">
                <div class="fp-content-btn" *ngIf="vehicle?.tagEntityConfirmation">
                    <button class="btn-item-toogle fp-btn-secondary mr" *ngIf="vehicle?.tagEntityConfirmation"
                        [disabled]="this.tagsActions" [matTooltip]="this.tagsActions ? 'Comuniquese con Facilpass' : ''"
                        type="submit" id="btn-item-change-tag-{{vehicle?.vehicle.licensePlate}}" (click)="changeTag()">
                        <span class="btn-content-text" id="text-change-create">
                            Cambiar Tag
                        </span>
                    </button>
                </div>
                <button class="btn-item-toogle fp-btn-tertiary mr" *ngIf="!vehicle?.tagEntityConfirmation" type="submit"
                    [disabled]="this.tagsActions" [matTooltip]="this.tagsActions ? 'Comuniquese con Facilpass' : ''"
                    id="btn-item-associated-tag-{{vehicle?.vehicle.licensePlate}}" (click)="associatedTag()">
                    <span class="btn-content-text" id="text-associated-create">
                        Asociar TAG
                    </span>
                </button>
                <div class="fp-content-btn-delete">
                    <button class="fp-btn-white fp-btn-trash" type="button" (click)="deleteVehicle()">
                        <img src="assets/images/vehicle/icon-trash.svg" alt="icono eliminar">
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
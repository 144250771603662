import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from "@angular/router";
import { IUser } from "@app/models/user";
import { CommonsService } from "@shared/services/commons.service";
import { AuthenticationService } from "@singup/services/authentication.service";
import { from, Observable } from "rxjs";
/**
 * Injectable
 */
@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  /**
   * Constructor
   *
   * @param router Router
   * @param jwtHelperService JwtHelperService
   * @param authenticationService Autentication Service
   * @param commonService
   */
  constructor(
    private authenticationService: AuthenticationService,
    private commonService: CommonsService,
    private router: Router,
  ) {}
  /**
   * Activate route
   *
   * @param route
   * @param state
   */
  async canActivate(): Promise<boolean> {
    const user = await this.authenticationService.getCurrentAuthenticatedUser();
    if (!user) {
      this.router.navigate(["signin"]);
      return false;
    }

    const policyEnabled = await this.commonService.getPolicyEnabled();
    if (!policyEnabled) {
      this.router.navigate(["data-policy", "new-policy"]); // Redirige si no ha aceptado
      return false;
    }

    return true; // Permite el acceso
  }
}

import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from "@angular/core";
import { AppFacade } from "@app/app.facade";
import { IAccount } from "@app/models/all-data";
import { ITagInfo, IVehiclesInfo } from "@home/models/home";
import { CommonsService } from "@shared/services/commons.service";
import { IVehicleInformation } from "@vehicles/models/vehicle";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

export type ItemTemplate = "firstTemplate" | "secondTemplate";

@Component({
  selector: "fp-item-list-vehicle",
  templateUrl: "./item-list-vehicle.component.html",
  styleUrls: ["./item-list-vehicle.component.scss"],
})
export class ItemListVehicleComponent implements OnDestroy {
  @Input() vehicle: IVehiclesInfo;
  @Input() indexEven: boolean;
  @Input() set resetData(reset: boolean) {
    if (reset) {
      this.loading = false;
    }
  }
  @Input() typeTemplate: ItemTemplate = "firstTemplate";
  @Output() itemClickActive = new EventEmitter<ITagInfo>();
  @Output() vehicleInformation = new EventEmitter<IVehicleInformation>();
  @Output() openModalManagement = new EventEmitter<IVehiclesInfo>();
  @Output() openModalDelete = new EventEmitter<IVehiclesInfo>();
  destroy$: Subject<boolean> = new Subject<boolean>();
  loading: boolean = false;
  moreOptions: boolean = false;
  tagsActions: boolean;

  constructor(
    private commonsService: CommonsService,
    private appFacade: AppFacade,
  ) {
    this.selectAccount();
  }
  changeStatusTag() {
    if (this.loading) {
      return;
    }
    this.itemClickActive.emit(this.vehicle.tagEntityConfirmation);
    this.loading = true;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  buyTag(): void {
    this.vehicleInformation.emit({
      licensePlate: this.vehicle.vehicle.licensePlate,
      vehicleType: this.vehicle.vehicle.categoryValue,
    });
  }

  /**
   * redirect to change tag
   */
  changeTag(): void {
    this.commonsService.redirectToTagWithVehicleInformation(
      {
        licensePlate: this.vehicle.vehicle.licensePlate,
        vehicleType: this.vehicle.vehicle.categoryValue,
        accountId: this.vehicle.account,
        tagEntityConfirmationId: this.vehicle.tagEntityConfirmation.id,
      },
      "/tag/activation",
    );
  }

  /**
   * redirect to associated tag
   */
  associatedTag(): void {
    this.commonsService.redirectToTagWithVehicleInformation(
      {
        licensePlate: this.vehicle.vehicle.licensePlate,
        vehicleType: this.vehicle.vehicle.categoryValue,
      },
      "/tag/activation",
    );
  }

  deleteVehicle(): void {
    this.openModalDelete.emit(this.vehicle);
  }

  toggleMoreOptions(): void {
    this.moreOptions = !this.moreOptions;
  }

  openModalMoreOptions(): void {
    this.openModalManagement.emit(this.vehicle);
  }

  selectAccount(): void {
    this.selectAccountSelected$
      .pipe(takeUntil(this.destroy$))
      .subscribe((action: IAccount) => {
        this.tagsActions = action.data[0].statusAccountCod != "18151";
      });
  }

  /**
   * Select account selected
   * @readonly
   * @type {Observable<IAccounts>}
   * @memberof AdminMenuComponent
   */
  get selectAccountSelected$(): Observable<IAccount> {
    return this.appFacade.selectAccounts$;
  }
}

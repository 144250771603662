import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";

import { AppFacade } from "@app/app.facade";
import { IAccount } from "@app/models/all-data";
import { Languages } from "@app/store/actions/app.action";
import { TranslateService } from "@ngx-translate/core";
import { TransactionsService } from "@transactions/services/transactions.service";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
  CardTransactions,
  ViewCardTransaction,
} from "../../models/card-transactions";

@Component({
  selector: "fp-credit-card-transactions",
  templateUrl: "./credit-card-transactions.component.html",
  styleUrls: ["./credit-card-transactions.component.scss"],
})
export class CreditCardTransactionsComponent implements OnInit, OnDestroy {
  accountId: number;

  transactionError: boolean = false;

  loading: boolean = true;

  destroy$: Subject<boolean> = new Subject<boolean>();

  lastEvaluatedKey: string;

  loadingMore: boolean = false;

  transactions: ViewCardTransaction[] = [];

  hasMoreData: boolean = true;

  isMobile: boolean = false;

  constructor(
    private appFacade: AppFacade,
    private transactionsService: TransactionsService,
    public translate: TranslateService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.selectLanguage$.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      if (value) {
        this.translate.use(value);
      }
    });
    await this.getAccounts();
    this.getTransactions(true);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    this.checkViewportWidth();
  }

  checkViewportWidth() {
    this.isMobile = window.innerWidth < 768;
  }

  async getTransactions(isInitialLoad: boolean) {
    if (isInitialLoad) {
      this.loading = true;
    }
    this.transactionError = false;
    const request = await this.transactionsService.getTransactionsByStatus({
      limit: 10,
      account: this.accountId.toString(),
      lastEvaluatedKey: this.lastEvaluatedKey,
    });
    request.pipe(takeUntil(this.destroy$)).subscribe(
      (response: CardTransactions) => {
        if (response) {
          if (isInitialLoad) {
            this.loading = false;
          }

          this.lastEvaluatedKey = response.paging.lastEvaluatedKey;

          if (!this.lastEvaluatedKey) {
            this.hasMoreData = false;
          }

          const newTransactions = response.results.map((transaction) => {
            return {
              transactionType: transaction.title,
              confirmedDateFormatted: transaction.confirmedDateFormatted,
              paymentMethod: transaction.paymentMethod,
              statusView: transaction.paymentStatus,
              totalAmount: transaction.totalAmount,
              brand: transaction.brand,
              cardMask: transaction.maskedCardNumber,
            } as ViewCardTransaction;
          });

          this.transactions = [...this.transactions, ...newTransactions];
        }
      },
      (error: any) => {
        if (isInitialLoad) {
          this.loading = false;
        }
        this.transactionError = true;
      },
    );
  }

  async getAccounts(): Promise<void> {
    this.selectAccounts$
      .pipe(takeUntil(this.destroy$))
      .subscribe((action: IAccount) => {
        this.accountId = action.data[0].accountId;
      });
  }

  async onScrollDown(): Promise<void> {
    if (!this.loadingMore && this.hasMoreData) {
      this.loadingMore = true;
      await this.getTransactions(false);
      this.loadingMore = false;
    }
  }

  getStatusCategory(status: string): string {
    const normalizedStatus = status.toLowerCase();

    const successStatuses = ["approved", "confirmed"];
    const rejectedStatuses = ["rejected", "expired", "abandoned", "failed"];
    const pendingStatuses = [
      "created",
      "pending",
      "pendingprovider",
      "onprocess",
      "unconfirmed",
    ];

    if (successStatuses.includes(normalizedStatus)) {
      return "approved";
    } else if (rejectedStatuses.includes(normalizedStatus)) {
      return "rejected";
    } else if (pendingStatuses.includes(normalizedStatus)) {
      return "pending";
    }

    return "unknown";
  }

  /**
   * get accounts from Selector
   */
  get selectAccounts$(): Observable<IAccount> {
    return this.appFacade.selectAccounts$;
  }

  get selectLanguage$(): Observable<Languages> {
    return this.appFacade.selectLanguage$;
  }
}

import { IAccount, ILogin, IUserInfo } from "@app/models/all-data";
import { ICustomerInfo } from "@app/models/customer-info";
import { ILoaderState } from "@app/models/loader";
import { IModules } from "@app/models/modules";
import { IAddress } from "@app/modules/tag/models/address";
import { ActionReducerMap } from "@ngrx/store";
import { ICities } from "@shared/models/cities";
import { IIdentifiTypesState } from "../state/app.state";
import { accountsReducer } from "./accounts.reducer";
import { addressReducer } from "./address.reducer";
import { appReducer, ILanguage } from "./app.reducer";
import { citiesReducer } from "./cites.reducer";
import { customerInfoReducer } from "./customer-info.reducer";
import { identificationTypesReducer } from "./identification-types.reducer";
import { loaderReducer } from "./loader.reducer";
import { loginsReducer } from "./login.reducer";
import { modulesReducer } from "./modules.reducer";
import { userInfoReducer } from "./user-info.reducer";
/**
 * Model to
 */
export interface IAppState {
  language: ILanguage;
  identificationTypes: IIdentifiTypesState;
  citiesReducer: ICities;
  loaderReducer: ILoaderState;
  modulesReducer: IModules;
  accountsReducer: IAccount;
  customerInfoReducer: ICustomerInfo;
  userInfoReducer: IUserInfo;
  addressReducer: IAddress;
  loginsReducer: ILogin;
}

export const AppRootReducer: ActionReducerMap<IAppState> = {
  language: appReducer,
  identificationTypes: identificationTypesReducer,
  citiesReducer,
  loaderReducer,
  modulesReducer,
  accountsReducer,
  customerInfoReducer,
  userInfoReducer,
  addressReducer,
  loginsReducer,
};

import { ILogin } from "@app/models/all-data";
import { createFeatureSelector, createSelector } from "@ngrx/store";
import { loginsFeatureName } from "../state/login.state";

export const LoginsRootSelector =
  createFeatureSelector<ILogin>(loginsFeatureName);

export const selectLoginsData = createSelector(
  LoginsRootSelector,
  (state: ILogin) => state,
);

import { IAccount } from "@app/models/all-data";

export const accountsFeatureName = "accountsReducer";

export const initialAccountState: IAccount = {
  data: null,
  error: null,
  loaded: null,
  loading: null,
};

import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AppFacade } from "@app/app.facade";
import { IAccount } from "@app/models/all-data";
import { ITagInfo, IVehiclesInfo } from "@home/models/home";
import { CommonsService } from "@shared/services/commons.service";
import { Observable, Subject, takeUntil } from "rxjs";

@Component({
  selector: "fp-vehicles-management",
  templateUrl: "./vehicles-management.component.html",
  styleUrls: ["./vehicles-management.component.scss"],
})
export class VehiclesManagementComponent {
  vehicle: IVehiclesInfo;
  @Input() loading: boolean = false;
  @Output() openModalDelete = new EventEmitter<IVehiclesInfo>();
  @Output() emitCloseModal = new EventEmitter<boolean>();
  @Output() emitChangeStatus = new EventEmitter<ITagInfo>();
  destroy$: Subject<boolean> = new Subject<boolean>();
  tooltipMessage: string = "Comuniquese con facilpass";
  @Input() set vehicleInfo(item: IVehiclesInfo) {
    if (item) {
      this.vehicle = item;
      this.loading = false;
    }
  }
  tagsActions: boolean;
  constructor(
    private commonsService: CommonsService,
    private appFacade: AppFacade,
  ) {
    this.selectAccount();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  closeModal(): void {
    this.emitCloseModal.emit(true);
  }

  changeStatusTag(): void {
    if (this.loading) {
      return;
    }
    this.loading = true;
    this.emitChangeStatus.emit(this.vehicle.tagEntityConfirmation);
  }

  /**
   * redirect to Buy tag form
   *
   * @param $event event with vehicle Information
   */
  redirectTobuyTag() {
    this.commonsService.redirectToTagWithVehicleInformation({
      licensePlate: this.vehicle.vehicle.licensePlate,
      vehicleType: this.vehicle.vehicle.categoryValue,
    });
  }

  /**
   * redirect to change tag
   */
  changeTag(): void {
    this.commonsService.redirectToTagWithVehicleInformation(
      {
        licensePlate: this.vehicle.vehicle.licensePlate,
        vehicleType: this.vehicle.vehicle.categoryValue,
        accountId: this.vehicle.account,
        tagEntityConfirmationId: this.vehicle.tagEntityConfirmation.id,
      },
      "/tag/activation",
    );
  }

  /**
   * redirect to associated tag
   */
  associatedTag(): void {
    this.commonsService.redirectToTagWithVehicleInformation(
      {
        licensePlate: this.vehicle.vehicle.licensePlate,
        vehicleType: this.vehicle.vehicle.categoryValue,
      },
      "/tag/activation",
    );
  }

  deleteVehicle(): void {
    this.openModalDelete.emit(this.vehicle);
  }

  selectAccount(): void {
    this.selectAccountSelected$
      .pipe(takeUntil(this.destroy$))
      .subscribe((action: IAccount) => {
        console.log(">>>", action.data[0].statusAccountCod);
        this.tagsActions = action.data[0].statusAccountCod != "18151";
      });
  }

  /**
   * Select account selected
   * @readonly
   * @type {Observable<IAccounts>}
   * @memberof AdminMenuComponent
   */
  get selectAccountSelected$(): Observable<IAccount> {
    return this.appFacade.selectAccounts$;
  }
}
